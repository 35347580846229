
.footer {
    left: 0;
    bottom: 0;
    width: 100%;
    text-align: center;
    padding: 20px 0;
}
  
  .container h5 {
    margin-bottom: 15px;
  }
  
  .container ul {
    padding-left: 0;
    list-style-type: none;
  }
  
  .container ul li {
    margin-bottom: 10px;
  }
  
  .container ul li a {
    text-decoration: none;
    color: #333;
  }
  
  .container ul li a:hover {
    text-decoration: underline;
  }
  
  .container p {
    margin-bottom: 0;
  }
  
