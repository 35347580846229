.menu-header {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.menu-title {
  font-weight: bold;
}

.menu-margin {
  margin-left: 150px;
  font-weight: bold;
}

@media (min-width: 992px) {
  .menu-title {
    margin: 0px auto 100px 525px;
    font-weight: bold;
  }
}

@media (max-width: 500px) {
  .menu-title {
    margin: 0px 60px 0px 140px;
    font-weight: bold;
  }
}